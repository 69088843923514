<template>
  <div class="border-b border-gray-300">
    <div class="flex relative mx-1">
      <div
        v-for="(item, index) in tabs"
        :key="index"
        class="relative"
        :class="[
          {
            'pointer-events-none': item.disabled,
            'cursor-pointer': !item.disabled,
          },
          isLarge ? 'px-4 py-6' : 'px-5 py-4',
        ]"
        :ref="
          (el) => {
            item.ref = el;
          }
        "
        @click="activateTab(item)"
      >
        <span
          class="text-sm"
          :class="{
            'text-gray-600': secondary,
            'font-normal': item.disabled,
            'text-dark': item.isActive,
            'font-semibold': !item.disabled,
          }"
        >
          {{ item.title }}
        </span>
        <div
          v-if="item.comingSoon"
          class="absolute top-1 -right-2 whitespace-nowrap text-3xs text-yellow-darker leading-3 px-1.5 py-0.5 rounded"
          style="background-color: #fefaf6"
        >
          Coming soon
        </div>
      </div>

      <slot name="tabs-end" />

      <div
        ref="underline"
        class="bottom-0 left-0 absolute border-t-4 border-blue-300 rounded-t"
        style="width: 143px"
        :class="{ 'transition-all': !noTransition }"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';

export default {
  props: {
    tabs: Object,
    firstActiveTabRule: {
      type: Function,
      default: (tabs) => tabs[0],
    },
    activateTabCallback: {
      type: Function,
      default: null,
    },
    main: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    noTransition: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
  },
  setup(props) {
    const underline = ref(null);

    const route = useRoute();

    const isLarge = props.size === 'lg';

    const isMedium = props.size === 'md';

    onMounted(() => {
      _moveUnderlineToItem(activeItem);
    });

    watch(
      () => route.name,
      () => {
        const item =
          props.tabs.find((tab) => route.name == tab.routeName) ||
          props.tabs[0];
        setActiveTab(item);
      }
    );

    let activeItem = props.firstActiveTabRule(props.tabs);

    const activateTab = function (item) {
      if (activeItem === item) return;

      activeItem.isActive = false;
      item.isActive = true;
      const from = { ...activeItem };
      const to = { ...item };

      if (props.activateTabCallback !== null)
        props.activateTabCallback(from, to);

      _moveUnderlineToItem(item);

      activeItem = item;
    };

    const setActiveTab = function (item) {
      activeItem.isActive = false;
      item.isActive = true;
      _moveUnderlineToItem(item);
      activeItem = item;
    };

    function _moveUnderlineToItem(item) {
      item.isActive = true;
      underline.value.style = `transform: translate3d(${item.ref.offsetLeft}px, 0, 0); width: ${item.ref.offsetWidth}px;`;
    }

    return {
      underline,
      activateTab,
      isLarge,
      isMedium,
    };
  },
};
</script>
